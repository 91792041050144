import * as React from "react"
import { Link } from "gatsby"

const Footer = ({ copyright }) => {

    const footerData = [
      {
        text: "Projects",
        link: "/projects",
      },
      { 
        text: "Services",
        link: "/services",
      },
      // {
      //   text: 'About', 
      //   link: "/about", 
      // },
      // {
      //   text: 'Careers', 
      //   link: "/careers", 
      // },
      {
        text: 'Contact', 
        link: "/contact", 
      },
      {
        text: 'Schedule a call', 
        link: "/schedule", 
      },
        // { 
        //   name: "Info",
        //   class: "footer-1",
        //   items: [
        //     {
        //       text: "Blog",
        //       link: "/blog",
        //     },
        //   ],
        // },
        // {
        //   name: "Get In Touch", 
        //   class: "footer-3",
        //   items: [
        //   ]
        // },
        // {
        //   name: "About Us", 
        //   class: "footer-2",
        //   items: [
        //     {
        //       text: "Reviews", 
        //       link: "/reviews", 
        //     }
        //   ]
        // },
    ]
    
    return (

    <footer 
      className="flex flex-col xl:flex-row xl:justify-around w-full bg-neutral-900 py-12 text-white text-center">   
            {/* <div className="w-full mb-12 sm:mb-0 sm:w-1/2 text-4xl text-center sm:text-left">
              ShopUX
            </div> */}
            {/* <Link className="mb-8 md:mb-0 text-5xl hover:cursor-pointer hover:text-neutral-400">
              Let's talk
            </Link> */}
            <div className="flex align-center justify-center mb-4">
              { footerData.map( ( item, idx ) => 
                 <Link className="mr-4 hover:cursor-pointer border-b-2 border-neutral-900 hover:border-white pb-px" to={item.link} key={ idx }>{item.text}</Link>                       
                  )}     
            </div>
            <div className="mb-4 text-neutral-300">Bringing your vision to life in <span className="text-white">Toronto, Canada</span>.</div>
            <div className="text-neutral-300">{ copyright }</div>
    </footer>
)}

export default Footer