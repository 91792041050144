import React from 'react';

export default [
    { 
        text: "Projects",
        link: "/projects",
        showMobile: true,
        showDesktop: true, 
    },
    { 
        text: "Services",
        link: "/services",
        showMobile: true, 
        showDesktop: true, 
    },
    { 
        text: "Blog",
        link: "/blog",
        showMobile: false, 
        showDesktop: false, 
    },
    { 
        text: "About Us",
        link: "/about",
        showMobile: false, 
        showDesktop: false, 
    },
    {
        text: "Contact",
        link: "/contact",
        showMobile: true,
        showDesktop: false, 
    },
]