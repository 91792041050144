import * as React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import navItems from "../data/menu"
import Hamburger from "./Hamburger"
 

// Mobile Menu components. 

const MobileNavCtaButton = ({ children }) => (
  <li>
    <Link href="/schedule" className="bg-gradient-to-r from-teal-400 to-cyan-600 hover:to-cyan-300 text-white px-8 py-4 rounded-full my-8 filter shadow-xl text-xl">
        { children }
    </Link>
  </li>
) 

const MobileNav = ({ navItems }) => {

  return (
    <>
        <div className="z-30 fixed w-full h-full bg-neutral-900" id="black-background" />
        <ul className="z-40 fixed w-full h-full flex flex-col items-center justify-center p-10 text-2xl list-none" id="mobile-menu" >
   
          { navItems.map( ( item, idx ) => 
            item.showMobile && <Link className="mb-8 text-white border-b-2 border-neutral-900" to={ item.link } activeClassName="border-b-2 border-white" key={ idx }>{item.text}</Link>
          )}

        <MobileNavCtaButton>Book a free call</MobileNavCtaButton>

        </ul>
    </>
  )
}

// Main navigation bar components

const NavItem = ({ item, isBgDark }) => {

  return (
    <li>
      <Link 
          className={`${ isBgDark ? 'border-white' : 'border-black'} ml-8 hover:border-b-2 pb-px text-base tracking-wide`} 
          to={item.link}
          activeClassName={`${ isBgDark ? 'border-white' : 'border-black'} border-b-2`}>
              {item.text}
      </Link>
    </li>
  )
}

const MainNavCtaButton = ({ isBgDark, children }) => (
  <Link href="/schedule" className={`${ isBgDark ? 'text-white sm:bg-white sm:border-none sm:text-black sm:hover:text-neutral-600' : 'text-black border-black sm:border-none sm:bg-neutral-800 sm:text-white sm:hover:bg-neutral-700'} border-b-2 pb-px mr-4 sm:mr-0 sm:no-border sm:px-4 sm:py-2 text-base sm:text-sm sm:rounded-full`}>
      { children }
  </Link>
)

const Header = ({ siteTitle, isBgDark }) => {

const [isMenuOpen, setMenuState] = useState(false);

  return (
    <>
      <header className={ `w-full z-50 absolute ${ isBgDark || isMenuOpen ? 'text-white' : 'text-black bg-transparent border-b border-b-px border-black'} top-0`}>
          <div className={`container mx-auto pt-6 pb-6 flex justify-between`}>
              <div className="flex justify-between items-center">
                  <Link to="/" className="text-2xl">{ siteTitle }</Link>
                  <ul className="hidden sm:flex list-none">
                    {navItems.map( ( item, idx ) => 
                      item.showDesktop && <NavItem item={ item } key={ idx } isBgDark={ isBgDark || isMenuOpen }/>
                      )}
                  </ul>   
              </div>  
              <div className="flex">
                  <MainNavCtaButton isBgDark={ isBgDark || isMenuOpen }>Book a free call</MainNavCtaButton>
                  <Hamburger isMenuOpen={ isMenuOpen } setMenuState={setMenuState} isBgDark={ isBgDark || isMenuOpen }/>
              </div>
          </div>
      </header>

      <div className="sm:hidden h-full">
        {isMenuOpen && <MobileNav navItems={ navItems } />}
      </div>
    </>
   )
}

export default Header