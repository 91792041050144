import React from 'react'

function Hamburger({isMenuOpen, setMenuState, isBgDark }){

    const toggleMenu = () => {
      setMenuState(!isMenuOpen);
    }
  
    return (
      <div className="flex items-center sm:hidden cursor-pointer" onClick={toggleMenu} onKeyDown={toggleMenu} role="button" tabIndex={0}>
        {isMenuOpen ?
  
          <div className="relative w-8 h-8">
            <div className={`absolute bottom-4 right-0 h-0.5 w-8 transform rotate-45 ${ isBgDark ? 'bg-white' : 'bg-black' }`}></div>
            <div className={`absolute bottom-4 right-0 h-0.5 w-8 transform -rotate-45 ${ isBgDark ? 'bg-white' : 'bg-black' }`}></div>
          </div> :           
          <div className="flex flex-col justify-center items-center w-8 h-8 group">
            <div className={`h-0.5 w-8 ${ isBgDark ? 'bg-white' : 'bg-black' }`}></div>
            <div className={`h-0.5 w-8 my-1.5 ${ isBgDark ? 'bg-white' : 'bg-black' }`}></div>
            <div className={`h-0.5 w-8 ${ isBgDark ? 'bg-white' : 'bg-black' }`}></div>
          </div>
            }
      </div>
    )
  
  }

export default Hamburger