  /**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./Header"
import Footer from "./footer"

const Layout = ({ location, children, isBgDark, page, loading }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          copyright
        }
      }
    }
  `)
  
  return (
    <div style={{
      minWidth: 320,
    }}>
      { !loading && <Header siteTitle={data.site.siteMetadata?.title || `Title`} location={ location } page={ page } isBgDark={ isBgDark }/>}
      <main>
        { children }
      </main>
      <Footer copyright={data.site.siteMetadata.copyright}/>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
